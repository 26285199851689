import type { OperatingZone } from '@orus.eu/operating-zone'

export const cguLink: Record<OperatingZone, string> = {
  fr: 'https://www.orus.eu/conditions-generales-dutilisation',
  // to update when we have the link
  es: 'https://www.orus.eu/conditions-generales-dutilisation',
}
export const confidentialityLink: Record<OperatingZone, string> = {
  fr: 'https://www.orus.eu/politique-de-confidentialite',
  // to update when we have the link
  es: 'https://www.orus.eu/politique-de-confidentialite',
}
